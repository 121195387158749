import styles from "./Layout.module.scss";
import Header from "@/components/Header";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import Sidebar from "@/components/Sidebar";
import Container from "@mui/material/Container";
import { useRouter } from "next/router";
import {
  useRoutes,
  RouteFeature,
  useTrackers,
  useLock,
  useResponsive,
  useCurrentTrip,
  useRestriction,
  usePromotionHandler,
} from "@/hooks";
import MobileSubroutesMenu from "@/components/MobileSubroutesMenu";
import { BottomNavigation } from "@/components/Navigation";
import Typography from "@/components/Typography";
import Head from "next/head";
import dayjs from "dayjs";
import TopLevelDialogs from "@/components/TopLevelDialogs";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import { Chat } from "@/components/Chat";
import cx from "classnames";
import { useTwilioConversation } from "@/components/Chat/hooks";
import SuggestionBanner from "@/components/SuggestionBanner";
import { useNotificationsSocket } from "@/fetch/notifications";
import { InitialLoader, CircularProgress } from "@/components/Loader";
import { Division } from "@/fetch/gworld";
import { SubrouteFeature } from "@/hooks/useRoutes";
import { useFcm } from "@/hooks";

const year = dayjs().year();
const Layout: React.FC<{}> = ({ children }) => {
  const {
    isLoading: isLoadingAuth,
    loginWithRedirect,
    isAuthenticated,
    user,
    getIdTokenClaims,
  } = useAuth0();
  const router = useRouter();
  const pathname = router.pathname;
  const { getMatchedRoute, getMatchedSubroute, groupSubroutesByIndex } =
    useRoutes();
  const { route } = getMatchedRoute();
  const subroute = getMatchedSubroute();
  const hasSidebar = route?.features?.includes(RouteFeature.Sidebar);
  const { currentTrip } = useCurrentTrip();
  const { isMobile } = useResponsive();
  const { isAllowedToNavigate, isLoading: isLockLoading } = useLock();
  const hasChat =
    !isMobile &&
    !subroute?.features?.includes(SubrouteFeature.HideChatPopover) &&
    isAllowedToNavigate &&
    currentTrip?.division !== Division.Tour;
  const sidebarTitle = route?.features?.includes(RouteFeature.SidebarTitle)
    ? route?.name
    : undefined;
  const subRouteGroups = groupSubroutesByIndex();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const isLoading = isLoadingAuth || isInitialLoading;
  const { identify, track, page } = useTrackers();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const {
    startTwilioListener,
    updateConversations,
    isChatPopOverOpen,
    setIsChatPopOverOpen,
  } = useTwilioConversation();

  /* Handle restricted pages entrance */
  useRestriction();

  /* start routine event-listeners of twilio chat */
  startTwilioListener();

  /* Initiate the notification socket */
  useNotificationsSocket();

  /* Initiate the FCM handler*/
  useFcm();

  /* Handle promotions available and show them on a popup*/
  usePromotionHandler();

  useEffect(() => {
    /* initially update the conversations*/
    updateConversations();
  }, [updateConversations]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      page(undefined, undefined);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, page]);

  useEffect(() => {
    if (!user) return;
    const getClaim = async () => {
      const tokenClaims = await getIdTokenClaims();
      const loginCounts =
        tokenClaims?.[`https://gworld.globalworkandtravel.com`]?.logins_counts;
      if (loginCounts === 1) {
        const rudderstack = global.window?.rudderanalytics;
        rudderstack?.track("First Login", { eventId: "first-login" });
      }
    };
    getClaim();
  }, [getIdTokenClaims, user]);

  useEffect(() => {
    if (!personalDetails) return;
    identify({
      email: personalDetails?.traveller?.email || "",
      id: `${personalDetails.traveller.id || ""}`,
      firstName: personalDetails?.traveller?.first_name || "",
      lastName: personalDetails?.traveller?.last_name || "",
    });
  }, [personalDetails, identify]);

  useEffect(() => {
    setIsInitialLoading(false);
  }, []);

  useEffect(() => {
    if (!isLoadingAuth && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: `${global.window?.location.pathname}${global.window?.location.search}`,
        },
      });
    }
  }, [isLoadingAuth, isAuthenticated, loginWithRedirect]);

  const SidebarToDisplay = route?.customSidebar ?? (
    <>
      {!isMobile && (
        <Sidebar
          title={sidebarTitle}
          isLoading={isLoading}
          sections={subRouteGroups}
          currentPath={pathname}
        />
      )}
      {isMobile && <MobileSubroutesMenu isLoading={isLoading} />}
    </>
  );

  return isLockLoading ? (
    <div className={styles.lockLoader}>
      <InitialLoader />
    </div>
  ) : (
    <Container className={cx(styles.root, route?.rootClassName)} maxWidth="lg">
      <Head>
        <title>gWorld - {subroute?.name || route?.name}</title>
        <meta name="robots" content="noindex"></meta>
        <meta name="googlebot" content="noindex"></meta>
      </Head>
      <TopLevelDialogs />
      {route?.customMobileHeader && isMobile ? (
        route.customMobileHeader
      ) : (
        <Header />
      )}

      <div className={styles.container}>
        <SuggestionBanner />
        <main>
          {hasSidebar && SidebarToDisplay}
          <section>{children}</section>
        </main>
      </div>
      <BottomNavigation />
      <footer className={styles.footer}>
        <Typography variant="caption">
          Copyright© {year} The Global Work &amp; Travel Co. All rights and
          lefts reserved.
        </Typography>
      </footer>
      {hasChat && (
        <div
          className={cx([styles.chatPopOver], {
            [styles.openedChatPopover]: isChatPopOverOpen,
          })}
        >
          <Chat
            isOpen={isChatPopOverOpen || false}
            onTogglePopover={() => setIsChatPopOverOpen?.((state) => !state)}
          />
        </div>
      )}
    </Container>
  );
};

export default Layout;
