import { useRouter } from "next/router";
import ExpertsDialog from "@/components/ExpertsDialog";
import HelpAndSupportDialog from "@/components/HelpAndSupportDialog";
import OnboardingTourDialog from "@/components/OnboardingTourDialog";
import RestrictedPageDialog from "@/components/RestrictedPageDialog";
import ViolationDialog from "@/components/ViolationDialog";
import { useDialog } from "@/hooks";
import { GroupMembersDialog } from "@/components/Social";
import { InvitationDialog } from "@/components/Rewards";
import { UpgradeDialog } from "@/components/Payment";
import EditProfileDialog from "@/components/Social/EditProfileDialog";
import GappForceUpdateDialog from "@/components/GappForceUpdateDialog";
import AppointmentDialog from "@/components/AppointmentDialog";

export enum TopLevelDialogNames {
  Experts = "experts",
  Help = "help",
  GroupMembers = "group-members",
  EditProfile = "edit-profile",
  OnboardingTour = "onboarding-tour",
  Invitation = "invitation",
  RestrictedPage = "restricted-page",
  UpdateGapp = "update-gapp",
  Appointment = "appointment",
  Violation = "violation",
  Upgrade = "upgrade",
}

const TopLevelDialogs: React.FC<{}> = () => {
  const { query } = useRouter();
  const { onClose } = useDialog();

  const props = { onClose };

  switch (query.dialog as TopLevelDialogNames) {
    case TopLevelDialogNames.Experts:
      return <ExpertsDialog {...props} />;
    case TopLevelDialogNames.Help:
      return <HelpAndSupportDialog {...props} />;
    case TopLevelDialogNames.GroupMembers:
      return <GroupMembersDialog {...props} />;
    case TopLevelDialogNames.EditProfile:
      return <EditProfileDialog {...props} />;
    case TopLevelDialogNames.OnboardingTour:
      return <OnboardingTourDialog {...props} />;
    case TopLevelDialogNames.Invitation:
      return <InvitationDialog {...props} />;
    case TopLevelDialogNames.RestrictedPage:
      return <RestrictedPageDialog {...props} />;
    case TopLevelDialogNames.UpdateGapp:
      return <GappForceUpdateDialog {...props} />;
    case TopLevelDialogNames.Appointment:
      return <AppointmentDialog {...props} />;
    case TopLevelDialogNames.Violation:
      return <ViolationDialog {...props} />;
    case TopLevelDialogNames.Upgrade:
      return <UpgradeDialog {...props} />;

    default:
      return null;
  }
};

export default TopLevelDialogs;
